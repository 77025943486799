import React from 'react';
import { Select } from '@chakra-ui/react';
import InputWrapper from './InputWrapper';

export default function SelectField({
    id,
    name,
    label,
    required,
    register,
    errors,
    choices,
    helpText,
    placeholder = 'Option auswählen',
    areValuesAsync = false,
}) {
    if (areValuesAsync && Object.keys(choices).length === 0) {
        return null;
    }

    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            register={register}
            errors={errors}
            helpText={helpText}
        >
            <Select
                placeholder={placeholder}
                {...register(name, { required: true })}
            >
                {Object.keys(choices).map((k, index) => (
                    <option key={index} value={k}>
                        {choices[k]}
                    </option>
                ))}
            </Select>
        </InputWrapper>
    );
}
