import DatePicker, { registerLocale } from 'react-datepicker';
import InputWrapper from './InputWrapper';
import de from 'date-fns/locale/de';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

// https://gist.github.com/baumandm/8665a34bc418574737847f7394f98bd9

export default function DateTimeField({
    control,
    id,
    name,
    label,
    required,
    errors,
    placeholder,
    helpText,
    openToDate = dayjs().startOf('hour').hour(18).toDate(),
}) {
    registerLocale('de', de);

    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            // register={register}
            errors={errors}
            helpText={helpText}
        >
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <DatePicker
                        name={name}
                        placeholderText={placeholder}
                        autoComplete="off"
                        selected={value}
                        onChange={value => onChange(toUTC(value))}
                        // onChange={onChange}
                        openToDate={openToDate}
                        locale="de"
                        dateFormat="P"
                        isClearable
                    />
                )}
            />
        </InputWrapper>
    );
}

/**
 * https://day.js.org/docs/en/manipulate/utc-offset
 * @param {Date} date
 */
export function toUTC(date) {
    if (date === null) {
        return null;
    }

    var dayjs = require('dayjs');

    // utc plugin
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    let convertedString = dayjs(date).utc(true).format();

    return new Date(convertedString);
}
