import { Permissions } from '../share/Permissions';
import useMemberships from './useMemberships';

export default function useMembershipTrainers(company) {
    let filter = {
        where: {
            master_company_id: {
                _eq: company?.master_company_id,
            },
            role: {
                permissions: {
                    _contains: Permissions.APPOINTMENT_SELECTABLE,
                },
            },
            user_id: { _is_null: false },
        },
    };

    let [memberships, loading, refetch, error] = useMemberships(filter);
    return [memberships, loading, refetch, error];
}
