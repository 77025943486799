import { useQuery } from '@apollo/client';
import { GET_MEMBERSHIPS } from '@/components/graphql/memberships';
import { useAuthContext } from './authContext';

export default function useMemberships(filters = {}) {
    let { isAuthenticated } = useAuthContext();
    const { loading, error, data, refetch } = useQuery(GET_MEMBERSHIPS, {
        variables: filters,
        skip: !isAuthenticated(),
    });
    let memberships = data ? data?.memberships : undefined;

    return [memberships, loading, refetch, error];
}
