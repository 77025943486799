import getEnvironment from '@/components/environments/getEnvironment';

const periodChoices = [
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.DAILY,
        value: getEnvironment().appointmentTemplatePeriod.DAILY,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.WEEKDAYS,
        value: getEnvironment().appointmentTemplatePeriod.WEEKDAYS,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.WEEKEND,
        value: getEnvironment().appointmentTemplatePeriod.WEEKEND,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.MONDAY,
        value: getEnvironment().appointmentTemplatePeriod.MONDAY,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.TUESDAY,
        value: getEnvironment().appointmentTemplatePeriod.TUESDAY,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.WEDNESDAY,
        value: getEnvironment().appointmentTemplatePeriod.WEDNESDAY,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.THURSDAY,
        value: getEnvironment().appointmentTemplatePeriod.THURSDAY,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.FRIDAY,
        value: getEnvironment().appointmentTemplatePeriod.FRIDAY,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.SATURDAY,
        value: getEnvironment().appointmentTemplatePeriod.SATURDAY,
    },
    {
        label: getEnvironment().appointmentTemplatePeriodLabel.SUNDAY,
        value: getEnvironment().appointmentTemplatePeriod.SUNDAY,
    },
    // {label:getEnvironment().appointmentTemplatePeriodLabel.WEEKLY, value: getEnvironment().appointmentTemplatePeriod.WEEKLY,},
    // {label:getEnvironment().appointmentTemplatePeriodLabel.MONTHLY, value: getEnvironment().appointmentTemplatePeriod.MONTHLY,},
];

export default periodChoices;
