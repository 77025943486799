import React from 'react';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Grid,
    GridItem,
    Text,
} from '@chakra-ui/react';
import _ from 'lodash';

export default function InputWrapper({
    id,
    name,
    label,
    required,
    errors,
    children,
    helpText,
}) {
    let errorMsg = _.get(errors, name)?.message;

    return (
        <FormControl isRequired={required} id={id} isInvalid={errorMsg} mt="4">
            <Grid
                templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
                gap={[0, 6]}
            >
                <FormLabel mt={[0, 2]}>{label}</FormLabel>
                <GridItem colSpan={2}>
                    {children}
                    {helpText && (
                        <Text fontSize="xs" mt="1" ml="1" color="gray.400">
                            {helpText}
                        </Text>
                    )}
                    {errorMsg && (
                        <FormErrorMessage>{errorMsg}</FormErrorMessage>
                    )}
                </GridItem>
            </Grid>
        </FormControl>
    );
}
