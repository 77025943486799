import React from 'react';
import { Textarea } from '@chakra-ui/react';
import InputWrapper from './InputWrapper';

export default function TextField({
    id,
    name,
    label,
    required,
    register,
    errors,
}) {
    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            register={register}
            errors={errors}
        >
            <Textarea {...register(name, { required: true })} />
        </InputWrapper>
    );
}
