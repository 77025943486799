import { Checkbox, FormControl, FormErrorMessage } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

export default function CheckboxField({
    id,
    name,
    label,
    required,
    register,
    errors,
    content,
}) {
    let errorMsg = _.get(errors, name)?.message;

    return (
        <FormControl isRequired={required} id={id} isInvalid={errorMsg} mt="6">
            <Checkbox {...register(name, { required: true })}>{label}</Checkbox>
            {content}
            {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
        </FormControl>
    );
}
