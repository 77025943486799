import React from 'react';
import { Input } from '@chakra-ui/react';
import InputWrapper from './InputWrapper';

export default function InputField({
    id,
    name,
    label,
    required,
    register,
    errors,
    placeholder,
    helpText,
    isDisabled,
}) {
    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            register={register}
            errors={errors}
            helpText={helpText}
        >
            <Input
                {...register(name, { required: true })}
                placeholder={placeholder}
                isDisabled={isDisabled}
            />
        </InputWrapper>
    );
}
