import { useState, useEffect } from 'react';
import {
    Flex,
    Box,
    Text,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';
import InputWrapper from './InputWrapper';

export default function SelectField({
    id,
    name,
    label,
    required,
    register,
    errors,
    choices,
    helpText,
    // placeholder = 'Option auswählen',
    areValuesAsync = false,
    setValue,
    // getValues,
    initialValue = '',
}) {
    const [day, setDay] = useState('');
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const handleChangeDay = value => setDay(value);
    const handleChangeHour = value => setHour(value);
    const handleChangeMinute = value => setMinute(value);

    useEffect(() => {
        let minutes = initialValue;
        // let minutes = getValues(name)
        let { d, h, m } = parseMinutes(minutes);
        setDay(d === 0 ? '' : d);
        setHour(h === 0 ? '' : h);
        setMinute(m === 0 ? '' : m);
    }, [initialValue]);

    useEffect(() => {
        let minutes = toMinutes(day, hour, minute);

        setValue(name, minutes.toString());
    }, [day, hour, minute]);

    if (areValuesAsync && Object.keys(choices).length === 0) {
        return null;
    }

    // setValue(name, token)

    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            register={register}
            errors={errors}
            helpText={helpText}
        >
            <Flex justifyContent="space-between">
                <Box>
                    <Text fontSize="sm" mb="1" mt="0">
                        Tage:
                    </Text>
                    <NumberInput
                        size="sm"
                        defaultValue=""
                        min={0}
                        w="20"
                        value={day}
                        onChange={handleChangeDay}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box>
                    <Text fontSize="sm" mb="1" mt="0">
                        Stunden:
                    </Text>
                    <NumberInput
                        size="sm"
                        min={0}
                        max={23}
                        w="20"
                        value={hour}
                        onChange={handleChangeHour}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
                <Box>
                    <Text fontSize="sm" mb="1" mt="0">
                        Minuten:
                    </Text>
                    <NumberInput
                        size="sm"
                        min={0}
                        max={59}
                        w="20"
                        step={15}
                        value={minute}
                        onChange={handleChangeMinute}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Box>
            </Flex>
        </InputWrapper>
    );
}

function parseMinutes(minutes) {
    if (minutes === '') {
        minutes = 0;
    }
    minutes = parseInt(minutes);

    let d = Math.floor(minutes / (60 * 24));
    minutes = minutes - d * 24 * 60;

    let h = Math.floor(minutes / 60);
    minutes = minutes - h * 60;

    let m = minutes;

    return { d, h, m };
}

function toMinutes(d, h, m) {
    d = d === '' ? 0 : parseInt(d);
    h = h === '' ? 0 : parseInt(h);
    m = m === '' ? 0 : parseInt(m);

    d = d * 24 * 60;
    h = h * 60;

    return d + h + m;
}
