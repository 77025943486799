const timeDurationChoices = [
    { label: '5 Minuten vorher', value: '5' },
    { label: '10 Minuten vorher', value: '10' },
    { label: '15 Minuten vorher', value: '15' },
    { label: '30 Minuten vorher', value: '30' },
    { label: '45 Minuten vorher', value: '45' },
    { label: '1 Stunde vorher', value: '60' },
    { label: '2 Stunden vorher', value: '120' },
    { label: '3 Stunden vorher', value: '180' },
    { label: '4 Stunden vorher', value: '240' },
    { label: '6 Stunden vorher', value: '360' },
    { label: '12 Stunden vorher', value: '720' },
    { label: '1 Tag vorher', value: '1440' },
    { label: '2 Tage vorher', value: '2880' },
    { label: '3 Tage vorher', value: '4320' },
    { label: '4 Tage vorher', value: '5760' },
    { label: '5 Tage vorher', value: '7200' },
    { label: '6 Tage vorher', value: '8640' },
    { label: '7 Tage vorher', value: '10080' },
    { label: '8 Tage vorher', value: '11520' },
    { label: '9 Tage vorher', value: '12960' },
    { label: '10 Tage vorher', value: '14400' },
    { label: '12 Tage vorher', value: '15840' },
    { label: '13 Tage vorher', value: '17280' },
    { label: '2 Wochen vorher', value: '20160' },
    // {label: '3 Wochen vorher', value: '30240',},
    // {label: '4 Wochen vorher', value: '40320',},
];

export default timeDurationChoices;
