import {
    isDev,
    makeRequest,
    toastError,
    toastSuccess,
    toInt,
    toTimeString,
    toUTCString,
} from '@/components/helper';
import periodChoices from '@/components/share/periodChoices';
import timeDurationChoices from '@/components/share/timeDurationChoices';
import { Button, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useCompany from '../context/useCompany';
import useMembershipTrainers from '../context/useMembershipTrainers';
import AppointmentFaker from '../faker/appointmentFaker';
import CheckboxField from './fields/CheckboxField';
import { toUTC } from './fields/DateField';
import DateHourMinuteField from './fields/DateHourMinuteField';
import DateTimeField from './fields/DateTimeField';
import FormTitle from './fields/FormTitle';
import InputField from './fields/InputField';
import NumberField from './fields/NumberField';
import SelectField from './fields/SelectField';
import TextField from './fields/TextField';
import TimeField from './fields/TimeField';

function getValidation(withTemplate) {
    let rules = {
        name: yup.string().required('Name darf nicht leer sein'),
        trainer_id: yup.string().nullable(),
        duration: yup
            .number()
            .integer()
            .typeError('Eine Ganzzahl ist erforderlich')
            .positive('Die Zahl muss größer als 0 sein')
            .required('Dauer darf nicht leer sein'),
        description: yup.string(),
        max_participant: yup.string(),
        min_participant: yup.string(),
        // min_participant: yup.string(),
        bookable_from: yup.string(),
        // bookable_till: yup.string(),
        cancel_till: yup.string(),
        notification: yup.string(),
    };

    if (withTemplate) {
        rules['period'] = yup
            .string()
            .required('Zyklus darf bei einer Terminvorlage nicht leer sein');
        rules['time'] = yup
            .date()
            .typeError('Zeit darf nicht leer sein')
            .required();
    } else {
        rules['appointment'] = yup
            .date()
            .typeError('Datum darf nicht leer sein')
            .required();
    }

    return rules;
}

export default function AppointmentForm({
    isCreate,
    data,
    onFinished,
    isTemplate = false,
}) {
    let [company] = useCompany();
    let [trainers, isTrainerLoading, refreshTrainers] =
        useMembershipTrainers(company);
    const toast = useToast();

    // console.log(555, data)

    // get trainer
    let trainersForm = useMemo(() => {
        if (!trainers) {
            return {};
        }
        return Object.values(trainers).reduce((accumulator, membership) => {
            accumulator[membership.user_company.id] =
                membership.user_company.username;
            return accumulator;
        }, []);
    }, [trainers]);

    // refresh data
    useEffect(() => {
        refreshTrainers();
    }, []);

    // default
    let defaultValues = {
        max_participant: '',
        bookable_from: '',
        // bookable_till: '0',
        cancel_till: '',
        show_canceluser: true,
        notification: '120',
    };
    if (!isTemplate) {
        defaultValues['appointment'] = dayjs()
            .startOf('hour')
            .hour(18)
            .toDate();
    }

    // faker
    // if (isDev() && isCreate) {
    //     defaultValues = AppointmentFaker(isTemplate);
    // }

    useEffect(() => {
        if (!isCreate) {
            if (data.time) {
                if (typeof data.time === 'string') {
                    let [hour, minutes] = data.time.split(':');

                    // data.time = '15:00'
                    data.time = new Date();
                    data.time.setHours(hour);
                    data.time.setMinutes(minutes);
                }
            }
            let dataTransformed = { ...data };
            if (dataTransformed.max_participant === 0) {
                dataTransformed.max_participant = '';
            }
            if (dataTransformed.min_participant === 0) {
                dataTransformed.min_participant = '';
            }

            reset(dataTransformed);
        }
    }, [data]);

    // if (isCreate) {
    //     // default
    //     defaultValues = {
    //         appointment: dayjs().startOf('hour').hour(18).toDate(),
    //         max_participant: '',
    //         bookable_from: '',
    //         // bookable_till: '0',
    //         cancel_till: '',
    //         show_canceluser: true,
    //     }

    //     // faker
    //     if (isDev() && isCreate) {
    //         defaultValues = AppointmentFaker()
    //     }
    // } else {
    //     // update
    //     let {
    //         name,
    //         trainer_id,
    //         appointment,
    //         duration,
    //         description,
    //         max_participant,
    //         bookable_from,
    //         cancel_till,
    //         settings,
    //     } = data
    //     // if (isTemplate && !trainer_id) {
    //     //     trainer_id = data.trainer_id
    //     // }
    //     defaultValues = {
    //         name,
    //         trainer_id,
    //         appointment,
    //         duration,
    //         description,
    //         max_participant,
    //         bookable_from,
    //         cancel_till,
    //         settings,
    //     }

    //     if (isTemplate) {
    //         defaultValues['period'] = data.period
    //     }
    //     // console.log('defaultValues', defaultValues);
    // }

    const {
        register,
        handleSubmit,
        // watch,
        reset,
        formState: { isSubmitting, errors },
        setValue,
        getValues,
        control,
    } = useForm({
        defaultValues,
        resolver: yupResolver(yup.object().shape(getValidation(isTemplate))),
        mode: 'onBlur',
    });

    const onSubmit = async formValues => {
        try {
            if (formValues.time) {
                formValues.time = toTimeString(formValues.time);
            }
            if (formValues.appointment) {
                formValues.appointment = toUTCString(formValues.appointment);
            }
            formValues.duration = toInt(formValues.duration);
            formValues.bookable_from = toInt(formValues.bookable_from);
            formValues.cancel_till = toInt(formValues.cancel_till);
            formValues.max_participant = toInt(formValues.max_participant);
            formValues.min_participant = toInt(formValues.min_participant);
            formValues.notification = toInt(formValues.notification);

            if (formValues.min_participant > formValues.max_participant) {
                throw new Error(
                    'Mindestteilnehmerzahl kann nicht größer als Maximale Teilnehmerzahl sein!',
                );
            }

            let path;
            let msg;

            // console.log('formValues', formValues)

            if (isCreate) {
                path = isTemplate
                    ? '/appointmentTemplate/create'
                    : '/appointment/admin/create';
                await makeRequest(path, {
                    formData: formValues,
                    company_id: company.id,
                    master_company_id: company.master_company_id,
                });

                reset({});
                msg = isTemplate
                    ? 'Die Terminvorlage wurde angelegt.'
                    : 'Der Termin wurde angelegt.';
            } else {
                if (isTemplate) {
                    await makeRequest('/appointmentTemplate/update/', {
                        formData: formValues,
                        template_id: data.id,
                        company_id: company.id,
                        master_company_id: company.master_company_id,
                    });

                    msg = 'Die Terminvorlage wurde gespeichert';
                } else {
                    await makeRequest('/appointment/admin/update/', {
                        formData: formValues,
                        appointment_id: data.id,
                        company_id: company.id,
                        master_company_id: company.master_company_id,
                    });

                    msg = 'Der Termin wurde gespeichert';
                }
            }

            toastSuccess(toast, msg);
            onFinished();
        } catch (error) {
            toastError(toast, error);
        }
    };

    const formTimeDurationChoices = useMemo(() =>
        timeDurationChoices.reduce((accumulator, item) => {
            accumulator[item.value] = item.label;
            return accumulator;
        }, []),
    );

    const formPeriodChoicesChoices = useMemo(() =>
        periodChoices.reduce((accumulator, item) => {
            accumulator[item.value] = item.label;
            return accumulator;
        }, []),
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isTemplate && (
                <>
                    <FormTitle>Vorlage</FormTitle>

                    <SelectField
                        id="period"
                        name="period"
                        label="Zyklus"
                        required={true}
                        register={register}
                        errors={errors}
                        choices={formPeriodChoicesChoices}
                    />

                    <FormTitle>Termin</FormTitle>
                </>
            )}

            <InputField
                id="name"
                name="name"
                label="Name"
                placeholder="Name des Termin"
                required={true}
                register={register}
                errors={errors}
            />

            <SelectField
                id="trainer_id"
                name="trainer_id"
                label="Trainer"
                required={false}
                register={register}
                errors={errors}
                choices={trainersForm}
                areValuesAsync={true}
            />

            {isTemplate && (
                <TimeField
                    control={control}
                    id="time"
                    name="time"
                    label="Uhrzeit"
                    placeholder="HH:MM"
                    required={true}
                    register={register}
                    errors={errors}
                />
            )}
            {!isTemplate && (
                <DateTimeField
                    control={control}
                    id="appointment"
                    name="appointment"
                    label="Termin"
                    placeholder="DD.MM.YYYY HH:MM"
                    required={true}
                    register={register}
                    errors={errors}
                />
            )}

            <NumberField
                control={control}
                id="duration"
                name="duration"
                label="Dauer in Minuten"
                placeholder="Dauer des Termin"
                required={true}
                errors={errors}
                min={0}
            />

            <TextField
                id="description"
                name="description"
                label="Beschreibung"
                placeholder="..."
                register={register}
                errors={errors}
            />

            <FormTitle>Teilnehmerzahl</FormTitle>

            <NumberField
                control={control}
                id="max_participant"
                name="max_participant"
                label="Maximal Teilnehmerzahl"
                placeholder="Wieviele Teilnehmer können teilnehmen"
                required={false}
                errors={errors}
                min={0}
            />
            <NumberField
                control={control}
                id="min_participant"
                name="min_participant"
                label="Minimale Teilnehmerzahl"
                placeholder="Mindestteilnehmerzahl"
                helpText="Sollte die Teilnehmerzahl nicht erreicht werden, wird der Kurs automatisch storniert."
                required={false}
                errors={errors}
                min={0}
            />

            <FormTitle>Buchung</FormTitle>

            <DateHourMinuteField
                id="bookable_from"
                name="bookable_from"
                label="Buchbar ab"
                required={false}
                register={register}
                errors={errors}
                choices={formTimeDurationChoices}
                helpText="Ab wann kann der Termin gebucht werden"
                setValue={setValue}
                getValues={getValues}
                initialValue={data?.bookable_from}
            />

            <SelectField
                id="cancel_till"
                name="cancel_till"
                label="Stornieren bis"
                required={false}
                register={register}
                errors={errors}
                choices={formTimeDurationChoices}
                helpText="Bis wann man kann man Stornieren"
            />

            <FormTitle>Einstellungen</FormTitle>

            <SelectField
                id="notification"
                name="notification"
                label="Benachrichtigung"
                required={true}
                register={register}
                errors={errors}
                choices={formTimeDurationChoices}
                helpText="Zu diesem Zeitpunkt wird geprüft, ob die Mindestteilnehmerzahl erreicht ist und entweder eine Terminbenachrichtigung versandt oder der Termin abgesagt und benachrichtigt."
            />

            <CheckboxField
                id="show_canceluser"
                name="show_canceluser"
                label="Stornierte Teilnehmer anzeigen"
                // required={true}
                register={register}
                errors={errors}
            />

            <Button
                w="100%"
                mt={6}
                variant="solid"
                colorScheme="brand"
                isLoading={isSubmitting}
                type="submit"
            >
                {isCreate ? 'Erstellen' : 'Speichern'}
            </Button>
        </form>
    );
}
