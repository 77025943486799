import { toCurrentOffset, toDateOffset } from '@/components/helper';
import de from 'date-fns/locale/de';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import InputWrapper from './InputWrapper';

// https://gist.github.com/baumandm/8665a34bc418574737847f7394f98bd9

export default function DateTimeField({
    control,
    id,
    name,
    label,
    required,
    register,
    errors,
    placeholder,
    helpText,
    openToDate = dayjs().startOf('hour').hour(18).toDate(),
}) {
    registerLocale('de', de);

    // console.log(value)

    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            register={register}
            errors={errors}
            helpText={helpText}
        >
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value, name } }) => (
                    <DatePicker
                        name={name}
                        placeholderText={placeholder}
                        autoComplete="off"
                        // selected={toCurrentOffset(value)}
                        selected={new Date(value)}
                        // onChange={value => onChange(toDateOffset(value))}
                        onChange={value => onChange(value)}
                        openToDate={openToDate}
                        locale="de"
                        showTimeSelect
                        dateFormat="Pp"
                        timeIntervals={15}
                        // inline
                        isClearable
                        // showPopperArrow={false}
                    />
                )}
            />
        </InputWrapper>
    );
}
