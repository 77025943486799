import { Text } from '@chakra-ui/react';

export default function FormTitle({ children, ...props }) {
    return (
        <Text
            fontSize="sm"
            textTransform="uppercase"
            color="gray.400"
            mt="8"
            mb="2"
            fontWeight="semibold"
            letterSpacing="wider"
            {...props}
        >
            {children}
        </Text>
    );
}
