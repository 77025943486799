import React from 'react';
import InputWrapper from './InputWrapper';
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

export default function NumberField({
    control,
    id,
    name,
    label,
    required,
    register,
    errors,
    placeholder,
    helpText,
    defaultValue = undefined,
    min = undefined,
    max = undefined,
}) {
    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            register={register}
            errors={errors}
            helpText={helpText}
        >
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <NumberInput
                        defaultValue={defaultValue}
                        min={min}
                        max={max}
                        {...field}
                    >
                        <NumberInputField placeholder={placeholder} />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                )}
            />
        </InputWrapper>
    );
}
